import {
  IInterview, IListQuestion, IListQuestionOption, IPrice, IQuestion, IQuestionType, IStep,
} from 'features/settings/interviews/interfaces';
import { Event } from 'features/events/types/hubstr';
import { v4 as uuidv4 } from 'uuid';
import { QUESTION_TYPE } from 'features/settings/interviews/constants';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

/**
 * Получение полного списка опросников
 */
export const interviewRead = request.table<IInterview>(async ({ api, data }) => {
  try {
    const pagination = `count=${data.pagination?.items || 25}&page=${data.pagination?.page || 1}`;

    const res = await api.get<{ list: Event[], pager: Pager }>(
      `/api/admin/poll/full_list?${pagination}`,
    );

    if (res.error) {
      return {
        data: {
          rows: [],
          pagination: null,
        },
      };
    }

    return {
      data: {
        rows: res.data.list,
        pagination: null,
      },
    };
  } catch (e) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }
});
/**
 * Получение детальной информации об опроснике
 */
export const interviewGet = request.card<{interviewLayout: IInterview}>(async ({ api, data, parseError }) => {
  try {
    const res = await api.get<IInterview>(`/api/admin/poll/detail?poll=${data.id}`);
    //
    // проходим по всему опроснику и генерируем новые uuid
    //
    // if (res.data?.steps && !res.data?.isPublished) {
    //   res.data.steps = [...res.data?.steps.map((step: IStep) => ({
    //     ...step,
    //     uuid: uuidv4() as string,
    //     questions: [...step.questions.map((question: IQuestionType) => {
    //       if (question.type === QUESTION_TYPE.LIST) {
    //         //
    //         // новый uuid для вариантов ответа
    //         //
    //         return {
    //           ...question,
    //           options: [...(question as IListQuestion).options.map((option: IListQuestionOption) => ({
    //             ...option,
    //             uuid: uuidv4() as string,
    //           }))],
    //           uuid: uuidv4() as string,
    //         };
    //       }
    //
    //       return {
    //         ...question,
    //         uuid: uuidv4() as string,
    //       };
    //     })],
    //   }))];
    // }

    return {
      data: {
        interviewLayout: {
          id: data.id,
          name: res.data?.name,
          description: res.data?.description,
          steps: res.data?.steps,
          isPublished: res.data?.isPublished,
          basePrice: res.data?.basePrice,
          prices: res.data?.prices,
          canDelete: res.data?.canDelete,
          totalLimit: res.data?.totalLimit,
        },
      },
    };
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const interviewCreate = request.custom(async ({
  api, data, parseError, router,
}) => {
  const out = {
    name: data.data.interviewLayout.name,
    description: data.data.interviewLayout.description,
    steps: data.data.interviewLayout.steps,
    basePrice: data.data.interviewLayout.basePrice && data.data.interviewLayout.basePrice > 0
      ? data.data.interviewLayout.basePrice : null,
    prices: data.data.interviewLayout.prices.map((p: IPrice) => ({
      name: p.name,
      price: p.price,
      questionId: p.questionId,
    })),
  };

  if (data.data.interviewLayout.totalLimit) {
    out.totalLimit = data.data.interviewLayout.totalLimit;
  }

  const res = await api.post('/api/admin/poll/create', out);

  if (res.error) {
    return { error: parseError(res.error) };
  }
  router.push({ name: 'interviews' });
  return { data: 'ok' };
});

export const interviewUpdate = request.custom(async ({ api, data, parseError }) => {
  const questions: {uuid: string, question: string, description: string, intLimit?: number, totalLimit?: number}[] = [];

  if (data.data.interviewLayout.onlyMeta) {
    data.data.interviewLayout.steps.map((s: IStep) => {
      s.questions.map((q: IQuestion) => {
        const qi = {
          uuid: q.uuid,
          question: q.question,
          description: q.description,
        };

        if (q.intLimit) {
          qi.intLimit = q.intLimit;
        }

        if (q.totalLimit) {
          qi.totalLimit = q.totalLimit;
        }

        questions.push(qi);
        return q;
      });

      return s;
    });

    const res = await api.patch('/api/admin/poll/edit', {
      poll: data.id,
      totalLimit: data.data.interviewLayout?.totalLimit,
      questions,
    });

    if (res.error) {
      return { error: parseError(res.error) };
    }
  } else {
    const out = {
      poll: data.id,
      name: data.data.interviewLayout.name,
      description: data.data.interviewLayout.description,
      steps: data.data.interviewLayout.steps,
      basePrice: data.data.interviewLayout.basePrice && data.data.interviewLayout.basePrice > 0
        ? data.data.interviewLayout.basePrice : null,
      prices: data.data.interviewLayout.prices.map((p: IPrice) => ({
        name: p.name,
        price: p.price,
        questionId: p.questionId,
      })),
    };

    if (data.data.interviewLayout.totalLimit) {
      out.totalLimit = data.data.interviewLayout.totalLimit;
    }

    const res = await api.put('/api/admin/poll/edit', out);

    if (res.error) {
      return { error: parseError(res.error) };
    }
  }

  return { data: 'ok' };
});

export const interviewDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`/api/admin/poll/delete?poll=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'interviews' });
  return { data: 'ok' };
});
