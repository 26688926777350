const enCustom = {
  hubstr: {
    address: {
      sidebar: {
        table_title: 'Addresses',
        card_title: 'New address',
        group_title: 'Addresses',
      },
      form: {
        title: 'Address',

        submit_button: 'Save',
        cancel_button: 'Back',
        remove_button: 'Delete',

        name: { label: 'Name', placeholder: 'Office' },
        address: { label: 'Address', placeholder: '4 Krasnoarmeyskaya st., Moscow' },
        point: { label: 'Point on the map', placeholder: '55.797397, 37.549390' },
        description: { label: 'Description', placeholder: 'The entrance is on the right side' },
      },
      table: {
        title: 'Addresses',
        not_found: 'Addresses not found',

        address: 'Address',
        name: 'Name',
        description: 'Description',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'Name',
      },
    },
    event: {
      sidebar: {
        table_title: 'Events',
        card_title: 'New event',
        group_title: 'Events',
      },
      table: {
        title: 'Events',
        not_found: 'Events not found',

        id: 'ID',
        name: 'Name',
        category: 'Category',
        date_start: 'Start Date',
        date_end: 'End Date',
        status: 'Status',
        isPublished: 'Publication',

        published: 'published',
        not_published: 'not published',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'Name',
        planned_only: 'Planned',
        categories: 'Categories',
        date: 'Month',
      },
      tabs: {
        form: 'Card',
        guests: 'Residents',
      },
      form: {
        title: 'Event',
        submit_button: 'Save',
        submit_button_publish: 'Publish',
        cancel_button: 'Back',
        remove_button: 'Delete',

        name: {
          label: 'Name',
          placeholder: 'Business training',
          hint: 'Max 128 characters including spaces',
        },
        description: {
          label: 'Description',
          placeholder: 'At this business training we will learn how to manage team effectively',
        },
        date: {
          label: 'Start and end date',
          start_placeholder: '20.01.2022 14:00',
          end_placeholder: '21.01.2022  15:30',
        },
        image: {
          label: 'Image',
          hint: 'Recommended size - at least 800 pixels wide,'
            + '<br/>visible area in the proportions of 3:2. JPG, JPEG, PNG format. File size not more than 8 MB',
        },
        department: { label: 'Invite residents from departments', placeholder: '' },
        categories: { label: 'Categories', placeholder: 'Business, Human Resources' },
        organizer: { label: 'Organizers', placeholder: 'Ivan Ivanov' },
        publish: { label: 'Event publication' },
        address: { label: 'Address', placeholder: 'Moscow' },
        residentsLimit: { label: 'Max number of users', placeholder: '10' },
        access: {
          label: 'Access',
          placeholder: 'Private',
          opt_closed: 'Private',
          opt_half_open: 'Half-open event',
          opt_open: 'Open',
          opt_family: 'Family',
          opt_external: 'External',
        },
        type: {
          label: 'Type',
          placeholder: 'General',
          opt_main: 'General',
          opt_additional: 'Additional',
          opt_clubInClub: 'Club inside club',
          opt_open: 'Open',
        },
        isRegistrationOpen: {
          label: 'Registration',
          placeholder: '',
          opt_main: 'Open',
          opt_additional: 'Closed',
        },
        status: {
          label: 'Status',
          placeholder: 'Planned',
          opt_planned: 'Planned',
          opt_canceled: 'Canceled',
        },
        report: { label: 'Report', placeholder: 'The event was successful' },
        broadcast_link: { label: 'Link to view', placeholder: 'https://example.com' },
        materials_link: { label: 'Link to meeting materials', placeholder: 'https://example.com' },
        rating_statistics: { label: 'Statistics on given ratings:', xls: 'XLS', csv: 'CSV' },
        poll: {
          label: 'Attach survey',
          placeholder: 'Select a survey from the list',
        },
      },
      guests: {
        sidebar: {
          table_title: 'Participants',
          card_title: 'Event participants',
        },
        statuses: {
          willGo: 'Will go',
          favorite: 'In Favorites',
          noAnswer: 'No answer',
          new: 'Invited',
          willNotGo: 'Will not go',
          read: 'Viewed',
        },
        table: {
          title: 'Event participants',
          not_found: 'Participants not found',
          id: 'ID',
          name: 'Name',
          surname: 'Surname',
          email: 'Email',
          phone: 'Phone number',
          telegram: 'Telegram',
          status: 'Status',
        },
        filters: {
          name: 'Name or surname',
          status: 'Status',
        },
      },
      organizer: {
        title: {
          placeholder: 'Name of the organizing partner',
        },
        link: {
          placeholder: 'Link',
        },
      },
    },
    login: {
      title: 'Log in',
      submitButton: 'Log in',
      fields: {
        phone: { placeholder: '7-900-123-45-67', label: 'Phone number' },
        password: { placeholder: '******', label: 'Password' },
      },
      sidebar: {
        group_title: 'Settings',
      },
    },
    resident: {
      sidebar: {
        table_title: 'Residents',
        card_title: 'New resident',
        group_title: 'Residents',
        companies_title: 'Companies',
        company_title: 'Company',
      },
      tabs: {
        resident: 'Resident',
        companies: 'Companies',
        create: 'Create',
      },
      table: {
        title: 'Residents',
        not_found: 'Residents not found',
        id: 'ID',
        fio: 'Name',
        first_name: 'Name',
        last_name: 'Surname',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Date of birth',
        tags: 'Role at the club',
        avatar: 'Avatar',
        status: 'Status',
        authMethods: 'Authorization type',
        set_password: 'Set Password',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'Name or surname',
        phone: 'Phone number',
        positionTags: 'Role at the club',
        status: 'Status',
      },
      form: {
        title: 'Resident',
        cancel_button: 'Back',
        edit_button: 'Edit',
        submit_button: 'Save',
        remove_button: 'Remove',
        confirm_delete: 'Are you sure you want to remove this resident?',
        failed_password_change: 'Password update failure',

        about_me: 'About myself',
        about_business: 'About business',
        first_name: { label: 'Name', placeholder: 'Ivan' },
        last_name: { label: 'Surname', placeholder: 'Ivanov' },
        phone: { label: 'Phone number', placeholder: '+7-123-456-78-90' },
        email: { label: 'Email', placeholder: 'example{\'@\'}domain.com' },
        password: { label: 'Password for admin panel', placeholder: '' },
        roles: {
          label: 'Role',
          placeholder: 'Administrator',
          admin: 'Administrator',
          resident: 'Resident',
          community_manager: 'Community manager',
          buddy_partner: 'Buddy Partner',
        },
        birthday: { label: 'Date of birth', placeholder: '26.06.1980' },
        avatar: { label: 'Avatar' },
        badge: { label: 'Badge', placeholder: 'CEO of Atom LLC' },
        recommendation_user: { label: 'Joined on the recommendation', placeholder: 'Petr Petrov' },
        entry: { label: 'Member since', placeholder: '15.10.2021' },
        tag: { label: 'Tags', placeholder: 'Tag' },
        forum_group: { label: 'Forum Group', placeholder: 'Group' },
        position_tag: { label: 'Role at the club', placeholder: 'Moderator' },
        department: { label: 'Department', placeholder: '' },
        atlas: { label: 'Atlases', placeholder: '10' },
        city: { label: 'City', placeholder: 'Ulyanovsk' },
        business_start_year: { label: 'Seniority', placeholder: '01.03.2000' },
        business_start_year_display: { option: 'Hide section' },
        turnover_per_year: { label: 'Annual turnover, rubles', placeholder: '6 000 000 000' },
        turnover_per_year_display: { option: 'Hide section' },
        number_of_employees: { label: 'Number of employees', placeholder: '41' },
        number_of_employees_display: { option: 'Hide section' },
        business_sectors: { label: 'Business sectors', placeholder: 'IT, telecom' },
        gender: {
          label: 'Sex',
          option_m: 'Male',
          option_f: 'Female',
        },
        visibility: {
          show: 'Show',
          hide: 'Hide',
          range: 'Range',
        },
        telegram: { label: 'Telegram nickname', placeholder: '{\'@\'}butterfly' },
        telegramId: { label: 'Telegram ID', placeholder: '' },
        vk: { label: 'Vkontakte', placeholder: 'https:/vk.com/butterfly' },
        facebook: { label: 'Facebook', placeholder: 'https://fb.com/butterfly' },
        instagram: { label: 'Instagram', placeholder: '{\'@\'}butterfly' },
        education: { label: 'Education', placeholder: 'МГУ, 2014-2018' },
        family_status: { label: 'Marital status', placeholder: 'Single' },
        interests: { label: 'Interests, hobbies', placeholder: 'Swimming, horseback riding' },
        facts_about_me: { label: 'Facts about me', placeholder: 'When I was four years old, I was selling lemonade near my house.' },
        personal_www: { label: 'Personal site', placeholder: 'domain.com' },
        video_presentation: { label: 'Video business card', placeholder: 'https://youtube.com/some-video' },

        useful: 'What can I do',
        expertise: { label: 'Expertise', placeholder: 'I am good at chemistry.' },
        achievements: { label: 'Achievements', placeholder: 'Certificate "Medvezhonok", first place' },
        look_resources: { label: 'Looking for resources', placeholder: 'Calcium' },

        searching: 'What I am looking for',
        provide_resources: { label: 'Can provide resources', placeholder: 'Places in the office in Kaluga' },
        year_target: { label: 'Goal for the year', placeholder: 'Obtain an investment of $100 million' },
        request: { label: 'Request', placeholder: 'Looking for a business partner' },

        children: { label: 'Children', placeholder: 'A boy of 6 and a girl of 8' },
        contacts: { label: 'Contact info' },
        companies: {
          title: 'Company',
          submit_button: 'Save',
          remove_button: 'Delete',
          edit_button: 'Edit',
          add_button: 'Add',

          label: 'Companies',
          user: { label: 'Resident', placeholder: '' },
          name: { label: 'Company name', placeholder: 'Atom LLC' },
          logo: { label: 'Logo' },
          areas: { label: 'Sphere of business', placeholder: 'Telecom' },
          position: { label: 'Role in the company', placeholder: 'CEO' },
          geography: { label: 'Geography', placeholder: 'Central Russia' },
          site: { label: 'Website', placeholder: 'example.com' },
          telegram: { label: 'Company Telegram Channel', placeholder: '{\'@\'}company' },
          wasteTypes: { label: 'Types of recyclable waste', placeholder: '' },
        },
        status: {
          label: 'Status',
          active: 'Active',
          blocked: 'Blocked',
          left: 'Left the club',
          not_paid: 'Not paid',
        },
        newPassword: {
          label: 'Permanent password',
          placeholder: 'Enter your password',
        },
        authMethods: {
          label: 'Type of authorization',
          placeholder: 'Select at least one type of authorization',
        },
        externalId: {
          label: 'User ID in GetCourse',
          placeholder: 'Enter ID',
        },
        description: {
          label: 'Characteristics of a resident as a buddy-partner',
          placeholder: 'Description',
        },
      },
      companies: {
        table: {
          title: 'Resident companies',
          not_found: 'Companies not found',
          name: 'Name',
          logo: 'Logo',
          user_position: 'Position in the company',
          areas: 'Spheres of business',
          error: 'Failed to load the company',
        },
        filters: {
          name: 'Name',
        },
      },
    },
    club_role: {
      sidebar: {
        table_title: 'Roles at the club',
      },
      table: {
        title: 'Roles at the club',
        not_found: 'Roles not found',
        id: 'ID',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title_create: 'New role',
        title_edit: 'Role editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a role',
        failed_edition: 'Failed to edit a role',
        name: { label: 'Name', placeholder: 'Headman' },
        color: { label: 'Color', placeholder: '#FFFFFF' },
      },
    },
    event_category: {
      sidebar: {
        group_title: 'Event categories',
        table_title: 'Event categories',
      },
      table: {
        title: 'Event categories',
        not_found: 'Categories not found',
        edit_action: 'Edit',
        id: 'ID',
        name: 'Name',
        remove: 'Delete',
        actions: '',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title: 'New category',
        failed_creation: 'Failed to create category',
        failed_edition: 'Failed to edit category',
        submit_button: 'Save',
        remove_button: 'Delete',
        name: { label: 'Name', placeholder: 'Meeting' },
      },
    },
    partner: {
      sidebar: {
        table_title: 'Partners',
      },
      table: {
        title: 'Partners',
        not_found: 'Partners not found',
        id: 'ID',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title_create: 'New partner',
        title_edit: 'Partner editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a partner',
        failed_edition: 'Failed to edit partner',
        name: { label: 'Name', placeholder: 'Company LLC' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/company' },
        group: { label: 'Links group', placeholder: 'Выбрать' },
        image: { label: 'Image' },
      },
    },
    club_in_club: {
      sidebar: {
        table_title: 'Clubs inside the club',
      },
      table: {
        title: 'Clubs inside the club',
        not_found: 'Clubs not found',
        id: 'ID',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title_create: 'New club inside the club',
        title_edit: 'Club editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a club',
        failed_edition: 'Failed to edit the club',
        name: { label: 'Name', placeholder: 'Car enthusiasts' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Links group', placeholder: 'Select' },
        image: { label: 'Image' },
      },
    },
    complaints: {
      sidebar: {
        table_title: 'Complaints and blocks',
      },
      table: {
        title: 'Complaints',
        complaintAuthor: 'Who complained',
        complaintText: 'Text of the complaint',
        complaintObject: 'Subject of the complaint',
        commentary: 'Author of the comment/publication',
        complaintTime: 'Time of complaint',
        complaintActions: 'Controllers',
        complaintObserve: 'View complaint',
      },
      modal: {
        complaint_author: 'Who complained',
        complaint_text: 'Text of the complaint',
        complaint_object: 'Subject of the complaint',
        commentary: 'Author of the comment/publication',
        complaint_time: 'Time of complaint',
        complaint_published_time: 'Time of publication',
        complaint_published_text: 'Text of the publication',
        complaint_published_author: 'Author of the publication',
        complaint_comment_time: 'Time of the comment',
        complaint_comment_text: 'Text of the comment',
        complaint_comment_author: 'Author of the comment',
        complaint_event_time: 'Time of the event',
        complaint_event_name: 'Title of the event',
        cancel_button: 'Close',
      },
      filters: {
        complaints: 'Complaints',
        bans: 'Blocks',
      },
      form: {
        title_create: 'New club inside the club',
        title_edit: 'Club editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a club',
        failed_edition: 'Failed to edit the club',
        name: { label: 'Name', placeholder: 'Car enthusiasts' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Links group', placeholder: 'Select' },
        image: { label: 'Image' },
      },
    },
    bans: {
      sidebar: {
        table_title: 'Blocks',
      },
      table: {
        title: 'Blocks',
        company: 'Company',
        author: 'Creator\'s name',
        created: 'Creation date',
        actions: 'Controllers',
        remove: 'Unblock',
      },
      filters: {
        complaints: 'Complaints',
        bans: 'Blocks',
      },
      form: {
        title_create: 'New club inside the club',
        title_edit: 'Club editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a club',
        failed_edition: 'Failed to edit club',
        name: { label: 'Name', placeholder: 'Car enthusiasts' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Links group', placeholder: 'Select' },
        image: { label: 'Image' },
      },
    },
    community_manager: {
      sidebar: {
        table_title: 'Community Managers',
      },
      table: {
        title: 'Community Managers',
        not_found: 'Community managers not found',
        fio: 'Name',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Date of birth',
        avatar: 'Avatar',
        community_manager: 'Community',
        is_community_manager: 'Is the community manager',
        change_community_manager: 'Change manager',
        status: 'Status',
      },
      form: {
        title: 'Link to community manager',
        failed_action: 'Failed to link to community manager',
        submit_button: 'Save',
      },
      filters: {
        community_manager: 'Community Managers',
        name: 'Search by residents',
      },
      layout: {
        title: 'Link to community manager',
        sub_title: 'residents selected',
        placeholder: 'Select manager',
        loading: 'Loading',
        link: 'Unlink',
        unlink: 'Link',
      },
    },
    buddy_partner: {
      sidebar: {
        table_title: 'Buddy partners',
      },
      table: {
        title: 'Buddy partners',
        not_found: 'Buddy partners not found',
        fio: 'Name',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Date of birth',
        avatar: 'Avatar',
        buddy_partner: 'Buddy',
        is_buddy_partner: 'is buddy partner',
        change_buddy_partner: 'Change buddy partner',
        status: 'Status',
      },
      form: {
        title: 'Link to buddy partner',
        failed_action: 'Failed to link to buddy partner',
        submit_button: 'Save',
      },
      filters: {
        buddy_partner: 'Buddy partner',
        name: 'Search by residents',
      },
    },
    notification: {
      sidebar: {
        table_title: 'Mailings',
      },
      table: {
        title: 'Mailings',
        caption: 'Title',
        actions: 'Actions',
        edit_action: 'Edit',
        createAt: 'Date of creation',
        notifyAt: 'Date of mailing',
        published: 'Post',
      },
      filters: {
        create_new: 'New mailing',
      },
      form: {
        title: 'Mailing',
        cancel_button: 'Cancel',
        submit_button: 'Save',
        remove_button: 'Delete',
        caption: {
          label: 'Message title',
          placeholder: 'Type text',
        },
        text: {
          label: 'Message body',
          placeholder: 'Type text',
        },
        date: {
          label: 'Date and time of sending',
        },
        status: 'Mailing status',
        draft: 'Draft',
        types: {
          all: 'All',
          users: 'List of residents',
          group: 'Role in the club',
        },
        users_list_type: 'Mailing recipients',
        users: {
          label: 'Select residents',
          placeholder: 'Start typing the name...',
        },
        groupId: {
          label: 'Role in the club',
          placeholder: 'Select Role',
        },
        deeplink: {
          label: 'Deeplink',
          placeholder: 'Select link',
        },
        events: {
          label: 'Event',
          placeholder: 'Select event',
        },
        posts: {
          label: 'Posts',
          placeholder: 'Select post',
        },
      },
      status: {
        draft: 'Draft',
        planned: 'Scheduled',
        completed: 'Сompleted',
        failed: 'Error',
      },
    },
  },
  gaz: {
    interview: {
      sidebar: {
        table_title: 'Surveys',
        card_title: 'Survey',
      },
      table: {
        title: 'Surveys',
        not_found: 'No surveys found',
        id: 'ID',
        name: 'Name',
        description: 'Description',
        createdAt: 'Creation date',
        actions: 'Actions',
        published: 'Publication',
        is_published: 'Published',
        is_not_published: 'Not published',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title: 'Survey',
        failed_creation: 'Failed to create a survey',
        submit_button: 'Save',
        cancel_button: 'Cancel',
        remove_button: 'Delete',
        edit_button: 'Edit',
        name: { label: 'Name', placeholder: 'Event Evaluation' },
      },
      components: {
        condition: {
          question_select: {
            placeholder: 'Select step and question',
            step_label: 'Step',
            question_label: 'Question',
          },
          add_condition_btn: 'Condition',
          add_block_btn: 'Block',
          confirm: {
            message: 'Do you really want to remove this condition?',
            title: 'Attention',
            confirm: 'Yes, remove',
            cancel: 'Cancel',
          },
        },
      },
      constants: {
        question_type: {
          stars: 'Rating',
          plain_answer: 'Text field (long/short)',
          list: 'List',
          date: 'Date/Time',
          file: 'Download',
        },
        data_type: {
          string: 'Row',
          number: 'Number',
        },
        length_options: {
          short: 'Short',
          long: 'Long',
        },
        date_type: {
          date_time: 'Date and time',
          time: 'Time',
        },
        file_type: {
          photo: 'Photo',
          document: 'Document',
        },
      },
      layout: {
        name: 'Survey name',
        global_limit: 'Global limit',
        description: 'Survey description',
        price: 'Additional cost',
        step: 'Step',
        question: 'Question',
        confirm_question: {
          message: 'Do you really want to delete this question?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_step: {
          message: 'Do you really want to delete this step?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_move: {
          message: 'If you move this step, ALL of the display conditions in the survey will be removed. Continue?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_move_question: {
          message: 'If you move this question, ALL of the display conditions in the survey will be removed. Continue?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_price: {
          message: 'All cost adjustments will be reset. Continue?',
          title: 'Attention',
          confirm: 'Yes',
          cancel: 'Cancel',
        },
        modal_question: {
          title: 'Select question type',
          label: 'Question type',
          cancel: 'Cancel',
          ok: 'Select',
        },
        modal_price: {
          title: 'Cost adjustment',
          free: 'Free event',
          paid: 'Paid event',
          total_sum: 'Final price',
          base_price: 'Base price',
          question: 'Question',
          term: 'Summary',
        },
      },
      question: {
        title: 'Question',
        placeholder_question: 'Frame a question',
        placeholder_description: 'Type a brief description',
        label: 'Required question',
      },
      list_question: {
        confirm_delete: {
          message: 'Do you really want to delete this answer?',
          title: 'Attention',
          confirm: 'Yes, delete',
          cancel: 'Cancel',
        },
        title: 'Data type',
        self_answer: 'Resident can enter his answer',
        multi_select: 'Resident can choose several answers',
        total_limit: 'Total limit',
        show_new_screen: 'Open a list of answers on a new screen',
        option_placeholder: 'Type the answer',
        add_button: 'answer',
      },
      plain_answer_question: {
        title: 'Data type',
        text_length: 'Text length',
        int_limit: 'Limit',
        total_limit: 'Total limit',
      },
      file_question: {
        max_size: 'Maximum file size in MB',
        warning_1: 'Only one file can be attached to a question',
        warning_2: 'Attention! Files are stored on the server for 1 year from the moment of addition',
      },
    },
    profile: {
      sidebar: {
        title: 'Сabinet',
        table_title: 'Distribution by department',
        card_title: 'New subsection',
        group_title: 'Сabinet',
      },
      table: {
        title: 'Distribution by department',
        not_found: 'Subsections not found',
        id: 'ID',
        name: 'Subsection',
        departments: 'Departments',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
      },
      form: {
        title_create: 'New subsection',
        title_edit: 'Edit subsection',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create subsection',
        failed_edition: 'Failed to edit subsection',
      },
    },
    feedback: {
      sidebar: {
        table_title: 'Electronic reception',
      },
      table: {
        title: 'Electronic legal liaison',
        not_found: 'Not found',

        fio: 'Name',
        email: 'E-mail',
        text: 'Text of the resident\'s message',
        createdAt: 'Creation date',
        action_more: 'Learn more',
      },
      tabs: {
        suggestions: 'Suggestions for changes',
        breaking: 'Claims on violations',
      },
      filters: {
        text: 'Search by row',
      },
    },
    boss_word: {
      sidebar: {
        card_title: 'Word of the Chairman',
      },
      form: {
        submit_button: 'Save',
        cancel_button: 'Back',

        image: {
          label: 'Image',
          hint: 'Size up to 100 MB',
        },
        title: {
          label: 'Title',
          placeholder: 'type in the title text',
        },
        text: {
          label: 'Main text',
          placeholder: 'type text',
        },
      },
    },
    safety: {
      sidebar: {
        table_title: 'Occupational Health & Safety',
      },
      table: {
        title: 'Occupational Health & Safety',
        not_found: 'No suggestions found',

        fio: 'Name',
        email: 'E-mail',
        organization: 'Company',
        regulation_act: 'Local regulatory act',
        point: 'Item number of the act',
        text: 'Your suggestions',
        text_breaking: 'Description of violation',
        createdAt: 'Creation date',
        place: 'Place where the violation was detected',
        action_more: 'Learn more',
      },
      tabs: {
        suggestions: 'Suggestions for changes',
        breaking: 'Claims on violations',
      },
      filters: {
        text: 'Search by row',
      },
    },
  },
};

export type CustomTranslationSchema = typeof enCustom;
export default enCustom;
