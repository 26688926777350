import {
  accessField,
  addressField, broadcastLinkField,
  categoriesField,
  dateStartField,
  descriptionField,
  isRegistrationOpenedField,
  logoField,
  nameField,
  organizersField, reportField, residentsLimitField,
  statusField,
  typeField,
} from 'features/events/layouts/general';

export const cloverLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      {
        type: 'row',
        props: {
          elementsGrow: true,
        },
        fields: [nameField],
      },

      /** dateStart-dateEnd in one field */
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'date-picker-row',
        },
        fields: [dateStartField],
      },

      {
        type: 'column',
        props: {
          span: 24,
        },
        fields: [organizersField, descriptionField, logoField],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: { span: 12, xs: 24, styles: { 'padding-left': '12px' } },
    fields: [
      addressField,
      accessField,
      typeField,
      categoriesField,
      statusField,
      isRegistrationOpenedField,
      residentsLimitField,
      reportField,
      broadcastLinkField,
      {
        type: 'input',
        name: 'isPublished',
        props: {
          disabled: true,
          hidden: true,
        },
      },
    ],
  },
];
