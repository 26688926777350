<template>
  <el-row>
    <h2>{{ translateText('hubstr.community_manager.layout.title') }}</h2>
  </el-row>

  <el-row>
    <h4>{{ `${translateText('hubstr.community_manager.layout.sub_title')}: ${residentsList.length}` }}</h4>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-select
        :placeholder="translateText('hubstr.community_manager.layout.placeholder')"
        :loading="state.loading"
        :loading-text="translateText('hubstr.community_manager.layout.loading')"
        :filterable="true"
        :remote="true"
        :remote-method="remoteMethod"
        :model-value="state.selectedManager"
        :class="[{
          'select-error': state.error,
        }]"
        @change="selectManager"
      >
        <el-option
          v-for="option in state.managers"
          :key="option.id"
          :value="option.id"
          :label="option.fio"
        />
      </el-select>
    </el-col>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-button
        native-type="button"
        type="primary"
        @click="submitManager"
      >
        {{ state.selectedManager === '-1'
          ? translateText('hubstr.community_manager.layout.link')
          : translateText('hubstr.community_manager.layout.unlink') }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { Resident } from 'features/residents/types/hubstr';
import { communityManagerChange, communityManagersListGet } from 'features/settings/community-managers/requests/hubstr';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'CommunityManagerEditForm',
  props: {
    residentsList: {
      type: Array as PropType<Resident[]>,
      required: true,
    },
  },
  emits: ['success'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: {
      selectedManager: string,
      loading: boolean,
      managers: Resident[],
      error: boolean,
    } = reactive({
      selectedManager: '',
      loading: false,
      managers: [],
      error: false,
    });

    const remoteMethod = async (search = '') => {
      state.loading = true;
      const newOptions = await communityManagersListGet({ search });
      state.managers = newOptions.data?.rows.filter((item) => item.id !== '') || [];
      state.loading = false;
    };

    const selectManager = (id: string) => {
      state.error = false;
      state.selectedManager = id;
    };

    const submitManager = async () => {
      state.error = false;

      if (state.selectedManager.length === 0) {
        state.error = true;
        return;
      }

      await communityManagerChange({
        residentsList: props.residentsList,
        communityManager: state.selectedManager,
      });

      emit('success');
    };

    onMounted(() => {
      remoteMethod('');
    });

    return {
      state,

      remoteMethod,
      selectManager,
      submitManager,

      translateText: (code: string) => customT(translate(code)),
    };
  },
});
</script>

<style scoped>
h4 {
  margin: 0;
}
.el-row {
  margin-bottom: 30px;
}
:deep(.select-error .el-input__inner) {
  box-shadow: 0 0 0 1px #ff3333 inset!important;
}
</style>
