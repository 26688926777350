import OrganizerSelector from 'features/events/organizer-selector/organizer-selector.vue';
import { addressSearch } from 'features/addresses/requests/hubstr';
import { eventCategoriesSearch } from 'features/settings/event-categories/requests/hubstr';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { translate } from 'magner';

export const nameField = {
  type: 'input',
  name: 'name',
  label: translate('hubstr.event.form.name.label'),
  hint: translate('hubstr.event.form.name.hint'),
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.name.placeholder'),
  },
  validation: [
    {
      type: 'empty-required' as 'empty',
      trigger: 'change',
    },
    {
      type: 'max-length-128' as 'empty',
      trigger: 'change',
    },
  ],
};

export const dateStartField = {
  type: 'datetime',
  name: 'dateStart',
  dataType: 'array',
  label: translate('hubstr.event.form.date.label'),
  props: {
    required: true,
    type: 'datetimerange',
    format: 'DD.MM.YYYY HH:mm',
    startPlaceholder: translate('hubstr.event.form.date.start_placeholder'),
    endPlaceholder: translate('hubstr.event.form.date.end_placeholder'),
    defaultTime: [new Date(2000, 1, 1, 19, 0, 0), new Date(2000, 1, 1, 21, 0, 0)],
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};

export const organizersField = {
  type: 'custom',
  name: 'organizers',
  dataType: 'array',
  component: () => OrganizerSelector,
  label: translate('hubstr.event.form.organizer.label'),
  props: {
    placeholder: translate('hubstr.event.form.organizer.placeholder'),
    disabled: (data: { state: {isPublished: boolean} }) => !!data.state.isPublished,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const descriptionField = {
  type: 'textarea',
  name: 'description',
  label: translate('hubstr.event.form.description.label'),
  props: {
    required: true,
    rows: 10,
    placeholder: translate('hubstr.event.form.description.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const logoField = {
  type: 'dropzone',
  name: 'logo',
  label: translate('hubstr.event.form.image.label'),
  hint: translate('hubstr.event.form.image.hint'),
  props: {
    formats: ['jpg', 'png', 'jpeg', 'gif', 'webp'],
    required: true,
    removable: true,
    valueKey: 'id',
    srcKey: 'image',
    maxSize: 8,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};

export const addressField = {
  type: 'select',
  name: 'address',
  label: translate('hubstr.event.form.address.label'),
  options: [],
  props: {
    required: true,
    filterable: true,
    remote: true,
    remoteMethod: addressSearch,
    valueKey: 'id',
    labelKey: 'name',
    placeholder: translate('hubstr.event.form.address.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const accessField = {
  type: 'select',
  name: 'access',
  label: translate('hubstr.event.form.access.label'),
  options: [{
    label: translate('hubstr.event.form.access.opt_closed'),
    value: 'closed',
  }, {
    label: translate('hubstr.event.form.access.opt_half_open'),
    value: 'halfOpen',
  }, {
    label: translate('hubstr.event.form.access.opt_open'),
    value: 'open',
  }, {
    label: translate('hubstr.event.form.access.opt_family'),
    value: 'family',
  }, {
    label: translate('hubstr.event.form.access.opt_external'),
    value: 'external',
  }],
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.access.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const typeField = {
  type: 'select',
  name: 'type',
  label: translate('hubstr.event.form.type.label'),
  options: [{
    label: translate('hubstr.event.form.type.opt_main'),
    value: 'main',
  }, {
    label: translate('hubstr.event.form.type.opt_additional'),
    value: 'additional',
  }, {
    label: translate('hubstr.event.form.type.opt_clubInClub'),
    value: 'clubInClub',
  }, {
    label: translate('hubstr.event.form.type.opt_open'),
    value: 'open',
  }],
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.type.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const categoriesField = {
  type: 'select',
  name: 'categories',
  dataType: 'array',
  label: translate('hubstr.event.form.categories.label'),
  options: [],
  props: {
    required: true,
    multiple: true,
    placeholder: translate('hubstr.event.form.categories.placeholder'),
    filterable: true,
    remote: true,
    valueKey: 'id',
    labelKey: 'name',
    remoteMethod: eventCategoriesSearch,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const statusField = {
  type: 'select',
  name: 'status',
  label: translate('hubstr.event.form.status.label'),
  options: [{
    label: translate('hubstr.event.form.status.opt_planned'),
    value: 'planned',
  }, {
    label: translate('hubstr.event.form.status.opt_canceled'),
    value: 'canceled',
  }],
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.status.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const isRegistrationOpenedField = {
  type: 'radio',
  name: 'isRegistrationOpened',
  label: translate('hubstr.event.form.isRegistrationOpen.label'),
  options: [{
    label: translate('hubstr.event.form.isRegistrationOpen.opt_main'),
    value: true,
  }, {
    label: translate('hubstr.event.form.isRegistrationOpen.opt_additional'),
    value: false,
  }],
  props: {
    required: true,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const residentsLimitField = {
  type: 'input',
  name: 'residentsLimit',
  dataType: 'number',
  label: translate('hubstr.event.form.residentsLimit.label'),
  props: {
    placeholder: translate('hubstr.event.form.residentsLimit.placeholder'),
  },
};

export const invitedDepartmentsField = {
  type: 'select',
  name: 'invitedDepartments',
  dataType: 'array',
  options: [],
  label: translate('hubstr.event.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    multiple: true,
    remoteMethod: autocompleteDepartment,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const invitedDepartmentsNotRequiredField = {
  type: 'select',
  name: 'invitedDepartments',
  dataType: 'array',
  options: [],
  label: translate('hubstr.event.form.department.label'),
  props: {
    placeholder: translate('hubstr.event.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    multiple: true,
    remoteMethod: autocompleteDepartment,
  },
};

export const reportField = {
  type: 'textarea',
  name: 'report',
  label: translate('hubstr.event.form.report.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.event.form.report.placeholder'),
  },
};

export const broadcastLinkField = {
  type: 'input',
  name: 'broadcastLink',
  label: translate('hubstr.event.form.broadcast_link.label'),
  props: {
    placeholder: translate('hubstr.event.form.broadcast_link.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'change',
  }],
};

export const materialsLinkField = {
  type: 'input',
  name: 'eventMaterialsLink',
  label: translate('hubstr.event.form.materials_link.label'),
  props: {
    placeholder: translate('hubstr.event.form.materials_link.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'change',
  }],
};
