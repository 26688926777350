import { cardPageController, translate } from 'magner';
import type { CompanyData } from 'features/residents/companies/types/hubstr';
import {
  communityGet, communityCreate, communityUpdate, communityDelete,
} from 'features/residents/communities/requests';
import {
  communityLogoField,
  communityNameField,
  roleIntoField,
  descriptionField,
  communityTypesField,
  communityMembershipDescriptionField,
  communitySiteField,
  membersCountField,
  communityGeographyField,
  communityTelegramField,
  communityInstagramField,
} from 'features/residents/communities/card/general';

const companyConfig = cardPageController<CompanyData>({
  getRequest: communityGet,
  createRequest: communityCreate,
  updateRequest: communityUpdate,
  deleteRequest: communityDelete,

  confirmDelete: true,

  header: {
    title: translate('lc.resident.form.communities.title'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('hubstr.resident.form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.resident.form.companies.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('hubstr.resident.form.remove_button'),
          type: 'danger',
        },
      },
    ],
    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {},
          fields: [
            communityLogoField,
            communityNameField,
            communityTypesField,
            membersCountField,
            roleIntoField,
            communitySiteField,
            descriptionField,
            communityMembershipDescriptionField,
            communityGeographyField,
            communityTelegramField,
            communityInstagramField,
          ],
        },
      ] as any,
    },
  },
});

export default companyConfig;
