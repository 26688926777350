import {
  accessField,
  addressField, broadcastLinkField,
  categoriesField,
  dateStartField,
  descriptionField,
  isRegistrationOpenedField,
  invitedDepartmentsField,
  logoField,
  nameField,
  organizersField, reportField,
  statusField,
  typeField,
} from 'features/events/layouts/general';
import { translate } from 'magner';
import { getPollList } from 'features/events/requests/hubstr';

export const gazpromLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      {
        type: 'row',
        props: {
          elementsGrow: true,
        },
        fields: [nameField],
      },

      /** dateStart-dateEnd in one field */
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'date-picker-row',
        },
        fields: [dateStartField],
      },

      {
        type: 'column',
        props: {
          span: 24,
        },
        fields: [organizersField, descriptionField, logoField],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: { span: 12, xs: 24, styles: { 'padding-left': '12px' } },
    fields: [
      addressField,
      accessField,
      {
        type: 'select',
        name: 'type',
        label: translate('hubstr.event.form.type.label'),
        options: [{
          label: translate('hubstr.event.form.type.opt_main'),
          value: 'main',
        }, {
          label: translate('hubstr.event.form.type.opt_additional'),
          value: 'additional',
        }, {
          label: translate('hubstr.event.form.type.opt_open'),
          value: 'open',
        }, {
          label: translate('hubstr.event.form.type.opt_clubInClub'),
          value: 'clubInClub',
        }, {
          label: translate('hubstr.event.form.type.opt_sports'),
          value: 'sports',
        }, {
          label: translate('hubstr.event.form.type.opt_culture'),
          value: 'culture',
        }, {
          label: translate('hubstr.event.form.type.opt_voluntary'),
          value: 'voluntary',
        }, {
          label: translate('hubstr.event.form.type.opt_education'),
          value: 'education',
        }],
        props: {
          required: true,
          placeholder: translate('hubstr.event.form.type.placeholder'),
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      categoriesField,
      statusField,
      isRegistrationOpenedField,
      {
        type: 'input',
        name: 'residentsLimit',
        dataType: 'number',
        label: translate('hubstr.event.form.residentsLimit.label'),
        props: {
          placeholder: translate('hubstr.event.form.residentsLimit.placeholder'),
          disabled: (data: { state: {poll: {id: string}} }) => data.state.poll?.id?.length ?? false,
        },
      },
      invitedDepartmentsField,
      reportField,
      broadcastLinkField,
      {
        type: 'select',
        name: 'poll',
        dataType: 'array',
        options: [],
        label: translate('hubstr.event.form.poll.label'),
        props: {
          placeholder: translate('hubstr.event.form.poll.placeholder'),
          valueKey: 'id',
          labelKey: 'name',
          filterable: true,
          remote: true,
          remoteMethod: getPollList,
          disabled: (data: { state: {isPublished: boolean} }) => data.state.isPublished,
        },
      },
      {
        type: 'input',
        name: 'isPublished',
        props: {
          disabled: true,
          hidden: true,
        },
      },
    ],
  },
];
