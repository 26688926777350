import loginPageConfig from 'features/login/login';
import residentsConfig from 'features/residents/residents/hubstr';
import residentConfig from 'features/residents/resident/lc';
import communitiesConfig from 'features/residents/communities/table/lc';
import communityConfig from 'features/residents/communities/card/lc';
import eventsConfig from 'features/events/events/hubstr';
import eventConfig from 'features/events/event/hubstr';
import eventGuestsConfig from 'features/events/guests/guests/hubstr';
import { translate } from 'magner';
import eventCategoriesConfig from 'features/settings/event-categories/categories/hubstr';
import addressesConfig from 'features/addresses/addresses/hubstr';
import addressConfig from 'features/addresses/address/hubstr';
import clubRolesConfig from 'features/settings/club-roles/roles/hubstr';
import partnersConfig from 'features/settings/partners/partners/hubstr';
import complaintsConfig from 'features/settings/complaints/complaints/hubstr';
import bansConfig from 'features/settings/bans/bans/hubstr';

export const lcRouterConfig = {
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'residents',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('configs/empty.vue'),
        roles: false,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/hubstr/icons/logo-not-collapsed.svg'),
          headerCollapsedIcon: () => import('assets/hubstr/icons/logo-collapsed.svg'),

          sidebarGroups: [
            {
              name: 'settings-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('hubstr.login.sidebar.group_title'),
              routes: [
                'event-categories',
                'club-roles',
                'partners',
              ],
            },
          ],
        },
        routes: [
          /** Residents - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: residentsConfig,
            },
            route: {
              name: 'residents',
              path: '/residents',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.table_title'),
            },
          },
          /** Residents - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: residentConfig,
            },
            route: {
              name: 'resident',
              path: '/residents/:id',
              link: '/residents/new',
              roles: true,
              visible: false,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.card_title'),
            },
          },
          /** Communities - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: communitiesConfig,
            },
            route: {
              name: 'resident-communities',
              path: '/residents/:id/communities',
              roles: true,
              visible: false,
              title: translate('lc.resident.sidebar.companies_title'),
            },
          },
          /** Communities - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: communityConfig,
            },
            route: {
              name: 'community',
              path: '/communities/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.company_title'),
            },
          },
          /** Events - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventsConfig,
            },
            route: {
              name: 'events',
              path: '/events',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map-pin.svg'),
              title: translate('hubstr.event.sidebar.table_title'),
            },
          },
          /** Events - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: eventConfig,
            },
            route: {
              name: 'event',
              path: '/events/:id',
              link: '/events/new',
              roles: true,
              visible: false,
              title: translate('hubstr.event.sidebar.card_title'),
            },
          },
          /** Events - guests */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventGuestsConfig,
            },
            route: {
              name: 'event-guests',
              path: '/events/:id/guests',
              roles: true,
              visible: false,
              title: translate('hubstr.event.guests.sidebar.table_title'),
            },
          },
          /** Addresses - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: addressesConfig,
            },
            route: {
              name: 'addresses',
              path: '/addresses',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map.svg'),
              title: translate('hubstr.address.sidebar.table_title'),
            },
          },
          /** Addresses - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: addressConfig,
            },
            route: {
              name: 'address',
              path: '/addresses/:id',
              link: '/addresses/new',
              roles: true,
              visible: false,
              title: translate('hubstr.address.sidebar.card_title'),
            },
          },

          /** Settings block */
          /** Event categories */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventCategoriesConfig,
            },
            route: {
              name: 'event-categories',
              path: '/settings/event-categories',
              roles: true,
              visible: true,
              title: translate('hubstr.event_category.sidebar.table_title'),
            },
          },
          /** Club roles */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clubRolesConfig,
            },
            route: {
              name: 'club-roles',
              path: '/settings/club-roles',
              roles: true,
              visible: true,
              title: translate('hubstr.club_role.sidebar.table_title'),
            },
          },
          /** Partners */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: partnersConfig,
            },
            route: {
              name: 'partners',
              path: '/settings/partners',
              roles: true,
              visible: true,
              title: translate('hubstr.partner.sidebar.table_title'),
            },
          },
          /** Complaints - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: complaintsConfig,
            },
            route: {
              name: 'complaints',
              path: '/complaints',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.complaints.sidebar.table_title'),
            },
          },
          /** Bans - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bansConfig,
            },
            route: {
              name: 'bans',
              path: '/bans',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.bans.sidebar.table_title'),
            },
          },
        ],
      },
    },
  ],
};
