import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import { Resident } from 'features/residents/types/hubstr';
import { translate, useTranslate } from 'magner';
import { Community } from 'features/residents/communities/types';
import { request } from '~/utils/request';

export const communityRead = request.table<Community>(async ({ api, data }) => {
  const { customT } = useTranslate();
  const residentId = window.location.pathname.replace('/residents/', '').replace('/communities', '');
  const user = `?id=${residentId}`;

  const [resCommunities, resResident] = await Promise.all([
    api.get<{ list: Community[] }>(
      `/api/admin/user/community/list${user}`,
    ),
    api.get<{ user: Resident }>(`/api/admin/user/read?id=${residentId}`),
  ]);
  if (resCommunities.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  const headerEl = document.querySelector('.page .magner-page-header_title');
  if (headerEl && resResident.data) {
    const name = `${resResident.data.user.firstName} ${resResident.data.user.lastName}`;
    headerEl.textContent = `${customT(translate('lc.resident.communities.table.title'))} ${name}`;
  }

  return {
    data: {
      rows: resCommunities.data.data,
      pagination: null,
    },
  };
});

export const communityGet = request.card<Community>(async ({ api, data, parseError }) => {
  const { customT } = useTranslate();
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';
  const [resCommunity, resResident] = await Promise.all([
    api.get<Community>(`/api/admin/user/community/show?id=${data.id}`),
    api.get<{ user: Resident }>(`/api/admin/user/read?id=${residentId}`),
  ]);

  if (resCommunity.error || resResident.error) {
    return { error: { message: customT(translate('hubstr.resident.companies.table.error')), fields: {} } };
  }

  return {
    data: {
      ...resCommunity.data.data,
      ...resCommunity.data.data.additions,
      communityCity: resCommunity.data.data.additions.communityCity?.split(',') || null,
      roleInto: resCommunity.data.data.roleInto,
      userId: resResident.data.user as any,
    },
  };
});

// eslint-disable-next-line max-len
const createCommunityNestedKeys = [
  'communityCity',
  'communityContacts',
  'communityIndustries',
  'communityLookResources',
  'communityMembershipConditions',
  'communityMembershipDescription',
  'communityOtherSocials',
  'communityProvideResources',
  'communityYearTarget',
  'communityTelegram',
  'communityInstagram',
  'communityGeography',
];
const getRequestBody = async (data: any, id: string) => {
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';
  const body: any = {
    ...data.data, additions: {}, userId: residentId, id,
  };
  Object.entries(data.data).forEach(([key, val]) => {
    if (createCommunityNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });

  if (data.data.communityCity) body.additions.communityCity = data.data.communityCity.join(',');

  if (data.data.logo && data.data.logo?.length) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.logo as File[])[0],
      'community',
      'company_logo',
      'companyLogo',
      true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  } else {
    body.logo = null;
  }

  if (data.data.roleInto) body.roleInto = data.data.roleInto;
  if (!data.data.types.length) body.types = null;

  return body;
};

/**
 *
 */
export const communityCreate = request.card<Community>(async ({
  data, api, parseError, router,
}) => {
  const body = await getRequestBody(data, uuidv4());

  const res = await api.post<{ community: Community }>('/api/admin/user/community/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident-communities', params: { id: body.userId } });
  return { data: res.data?.community };
});

export const communityUpdate = request.card<Community>(async ({
  api, parseError, data, router,
}) => {
  const body = await getRequestBody(data, data.id as string);

  const res = await api.patch<{ community: Community }>('/api/admin/user/community/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident-communities', params: { id: body.userId } });
  return { data: res.data?.community };
});

export const communityDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`/api/admin/user/community/delete?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'residents' });
  return { data: 'ok' };
});
