import {
  actionTable,
  tablePageController, translate, useTranslate,
} from 'magner';
import { IInterview } from 'features/settings/interviews/interfaces';
import { ProfileLink } from 'features/profile/profile/types';
import { interviewRead } from './requests';
import { formatDate } from '~/utils/format-date';

const interviewsConfig = tablePageController<IInterview>({
  request: interviewRead,
  header: {
    title: translate('gaz.interview.table.title'),
    actions: [
      {
        type: 'link',
        to: { name: 'interview', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: translate('gaz.interview.filters.create_new'),
        },
      },
    ],
  },

  filters: {
    filtersInSeparatePanel: true,
    fieldsShowAmount: 5,
    filtersData: {
      // name: '',
    },
    actions: [],
    layout: [
      // {
      //   type: 'input',
      //   name: 'name',
      //   props: {
      //     placeholder: translate('gaz.interview.filters.name'),
      //   },
      // },
    ],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'name',
        label: translate('gaz.interview.table.name'),
      },
      {
        prop: 'description',
        label: translate('gaz.interview.table.description'),
      },
      {
        prop: 'createdAt',
        label: translate('gaz.interview.table.createdAt'),
        view: {
          type: 'text',
          formatter: (_, row) => formatDate(row.createdAt as unknown as Date),
        },
      },
      {
        prop: 'published',
        label: translate('gaz.interview.table.published'),
        view: {
          type: 'tags',
          formatter: (publish) => {
            const { customT } = useTranslate();

            return [{
              text: publish
                ? customT(translate('gaz.interview.table.is_published'))
                : customT(translate('gaz.interview.table.is_not_published')),
              backgroundColor: publish ? '#C60222' : '#00C04D',
              textColor: '#FFFFFF',
            }];
          },
        },
      },
      {
        prop: 'id',
        label: translate('gaz.interview.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              action: actionTable<ProfileLink>(async ({ data, router }) => {
                await router.push({ name: 'interview', params: { id: data.row.id } });
              }),
              props: {
                text: translate('gaz.interview.form.edit_button'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default interviewsConfig;
