import { translate } from 'magner';
import { autocompleteSearch } from 'features/residents/requests/hubstr';

/**
 * Лого сообщества
 */
export const communityLogoField = {
  type: 'dropzone',
  name: 'logo',
  label: translate('lc.resident.form.communities.logo.label'),
  props: {
    valueKey: 'id',
    srcKey: 'thumb_100',
    removable: true,
  },
};
/**
 * Название сообщества
 */
export const communityNameField = {
  type: 'input',
  name: 'name',
  label: translate('lc.resident.form.communities.name.label'),
  props: {
    required: true,
    placeholder: translate('lc.resident.form.communities.name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Роль в сообществе
 */
export const roleIntoField = {
  type: 'select',
  name: 'roleInto',
  options: [],
  label: translate('lc.resident.form.communities.roleInto.label'),
  props: {
    required: true,
    multiple: false,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('communityPosition'),
    placeholder: translate('lc.resident.form.communities.roleInto.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const roleIntoTextField = {
  type: 'input',
  name: 'roleInto',
  label: translate('lc.resident.form.communities.roleInto.label'),
  props: {
    required: true,
    placeholder: translate('lc.resident.form.communities.roleInto.placeholderText'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * О сообществе
 */
export const descriptionField = {
  type: 'textarea',
  name: 'description',
  label: translate('lc.resident.form.communities.description.label'),
  props: {
    required: true,
    rows: 3,
    placeholder: translate('lc.resident.form.communities.description.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Тип сообщества
 */
export const communityTypesField = {
  type: 'select',
  name: 'types',
  options: [],
  label: translate('lc.resident.form.communities.communityTypes.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('communityTypes'),
    placeholder: translate('lc.resident.form.communities.communityTypes.placeholder'),
  },
};
/**
 * Отраслевая принадлежность
 */
export const communityIndustriesField = {
  type: 'select',
  name: 'communityIndustries',
  options: [],
  label: translate('lc.resident.form.communities.communityIndustries.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('industries'),
    placeholder: translate('lc.resident.form.communities.communityIndustries.placeholder'),
  },
};
/**
 * Кого объединяете
 */
export const communityMembershipDescriptionField = {
  type: 'textarea',
  name: 'communityMembershipDescription',
  label: translate('lc.resident.form.communities.communityMembershipDescription.label'),
  props: {
    rows: 3,
    placeholder: translate('lc.resident.form.communities.communityMembershipDescription.placeholder'),
  },
};
/**
 * Какие у вас есть ресурсы?
 */
export const communityProvideResourcesField = {
  type: 'textarea',
  name: 'communityProvideResources',
  label: translate('lc.resident.form.communities.communityProvideResources.label'),
  props: {
    rows: 3,
    placeholder: translate('lc.resident.form.communities.communityProvideResources.placeholder'),
  },
};
/**
 * Цели на год вашего сообщества
 */
export const communityYearTargetField = {
  type: 'textarea',
  name: 'communityYearTarget',
  label: translate('lc.resident.form.communities.communityYearTarget.label'),
  props: {
    rows: 3,
    placeholder: translate('lc.resident.form.communities.communityYearTarget.placeholder'),
  },
};
/**
 * С какими запросами можно обращаться к вашему сообществу?
 */
export const communityLookResourcesField = {
  type: 'textarea',
  name: 'communityLookResources',
  label: translate('lc.resident.form.communities.communityLookResources.label'),
  props: {
    rows: 3,
    placeholder: translate('lc.resident.form.communities.communityLookResources.placeholder'),
  },
};
/**
 * Сайт сообщества
 */
export const communitySiteField = {
  type: 'input',
  name: 'site',
  label: translate('lc.resident.form.communities.site.label'),
  props: {
    required: true,
    placeholder: translate('lc.resident.form.communities.site.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Социальные сети сообщества
 */
export const communityOtherSocialsField = {
  type: 'textarea',
  name: 'communityOtherSocials',
  label: translate('lc.resident.form.communities.communityOtherSocials.label'),
  props: {
    rows: 3,
    placeholder: translate('lc.resident.form.communities.communityOtherSocials.placeholder'),
  },
};
/**
 * Город сообщества
 */
export const communityCityField = {
  type: 'select',
  name: 'communityCity',
  options: [],
  label: translate('lc.resident.form.communities.communityCity.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('city'),
    placeholder: translate('lc.resident.form.communities.communityCity.placeholder'),
  },
};
/**
 * Контакт для связи
 */
export const communityContactsField = {
  type: 'input',
  name: 'communityContacts',
  label: translate('lc.resident.form.communities.communityContacts.label'),
  props: {
    placeholder: translate('lc.resident.form.communities.communityContacts.placeholder'),
  },
};
/**
 * Количество участников
 */
export const membersCountField = {
  type: 'input',
  name: 'membersCount',
  dataType: 'number',
  label: translate('lc.resident.form.communities.membersCount.label'),
  props: {
    required: true,
    placeholder: translate('lc.resident.form.communities.membersCount.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Telegram-канал сообщества
 */
export const communityTelegramField = {
  type: 'input',
  name: 'communityTelegram',
  label: translate('lc.resident.form.communities.communityTelegram.label'),
  props: {
    placeholder: translate('lc.resident.form.communities.communityTelegram.placeholder'),
  },
};
/**
 * Инстаграм сообщества
 */
export const communityInstagramField = {
  type: 'input',
  name: 'communityInstagram',
  label: translate('lc.resident.form.communities.communityInstagram.label'),
  props: {
    placeholder: translate('lc.resident.form.communities.communityInstagram.placeholder'),
  },
};
/**
 * География
 */
export const communityGeographyField = {
  type: 'textarea',
  name: 'communityGeography',
  label: translate('lc.resident.form.communities.communityGeography.label'),
  props: {
    rows: 3,
    placeholder: translate('lc.resident.form.communities.communityGeography.placeholder'),
  },
};
