<template>
  <el-row>
    <h2>Определение области видимости для подраздела: <br>"{{ title }}"</h2>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-select
        v-model="state.selectedDepartments"
        :placeholder="'Выбрать отделение'"
        :loading="state.loading"
        :loading-text="'Загрузка'"
        :filterable="true"
        :remote="true"
        :remote-method="remoteMethod"
        multiple
        style="width: 100%;"
      >
        <el-option
          v-for="option in state.departments"
          :key="option.id"
          :value="option.id"
          :label="option.name"
        />
      </el-select>
    </el-col>
  </el-row>

  <el-row gutter="30">
    <el-col :span="12">
      <el-button
        native-type="button"
        type="primary"
        @click="submitDepartments"
      >
        Сохранить
      </el-button>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <el-button
        native-type="button"
        type="secondary"
        @click="selectAll"
      >
        Выбрать все
      </el-button>
      <el-button
        native-type="button"
        type="secondary"
        @click="clearAll"
      >
        Очистить
      </el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { profileUpdate } from 'features/profile/profile/requests';
import { Department } from 'features/profile/profile/types';

export default defineComponent({
  name: 'ProfileDepartmentsEditCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    selectedDepartments: {
      type: Array as PropType<Department[]>,
      required: true,
    },
  },
  emits: ['success'],
  setup (props, { emit }) {
    const state: {
      selectedDepartments: string[],
      loading: boolean,
      departments: Department[],
    } = reactive({
      selectedDepartments: props.selectedDepartments.map((d) => d.id),
      loading: false,
      departments: [],
    });

    const remoteMethod = async (search = '') => {
      state.loading = true;
      const newOptions = await autocompleteDepartment(search);
      state.departments = newOptions.data?.rows.filter((item) => item.id !== '') || [];
      state.loading = false;
    };

    const submitDepartments = async () => {
      await profileUpdate({
        linkId: props.id,
        departments: state.selectedDepartments,
      });

      emit('success');
    };

    const selectAll = () => {
      state.selectedDepartments = state.departments.map((d) => d.id);
    };

    const clearAll = () => {
      state.selectedDepartments = [];
    };

    onMounted(() => {
      remoteMethod('');
    });

    return {
      state,

      remoteMethod,
      submitDepartments,
      selectAll,
      clearAll,
    };
  },
});
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
}
</style>
