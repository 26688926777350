import {
  achievementsField,
  avatarField,
  badgeField,
  birthdayField,
  businessStartYearField,
  childrenField,
  cityField,
  dateOfEntryField,
  educationField,
  emailField,
  expertiseField,
  facebookField,
  factsAboutMeField,
  familyStatusField,
  firstNameField,
  instagramField,
  interestsField,
  lastNameField,
  numberOfEmployeesField,
  passwordField,
  passwordFieldRequired,
  personalWwwField,
  phoneField,
  provideResourcesField,
  roleField,
  statusField,
  telegramField,
  turnoverPerYearField,
  userLookExpertise,
  userLookResources, userRequest,
  userYearTarget,
} from 'features/residents/layouts/general';
import { translate } from 'magner';

export const lcLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      /** Lastname and Firstname */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [lastNameField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [firstNameField],
            }],
          },
        ],
      },
      /** Phone, Status */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [phoneField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [statusField],
            }],
          },
        ],
      },
      /** Password, role */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [{
                type: 'input',
                name: 'roleCurrent',
                props: {
                  disabled: true,
                  hidden: true,
                },
              }, roleField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [passwordField,
                passwordFieldRequired],
            }],
          },
        ],
      },
      /** Avatar */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          /** Avatar */
          {
            type: 'column',
            props: {
              span: 8,
              xs: 24,
            },
            fields: [avatarField],
          },
          /** Entry */
          {
            type: 'column',
            props: {
              span: 16,
              xs: 24,
              styles: { 'padding-left': '12px' },
            },
            layout: [
              badgeField,
              dateOfEntryField,
            ],
          },
        ],
      },

      /** Block about person (city, age, contact information, children etc.) */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_me'),
        props: { span: 24, isPaper: true, titleType: 'heading' },
        layout: [
          /** City and Birth date */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [cityField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [birthdayField],
                }],
              },
            ],
          },
          /** Facts about me */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [factsAboutMeField],
          },
          /** Interests (hobbies) */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [interestsField],
          },
          /** Education */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [educationField],
          },
          /** Family status and children */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [familyStatusField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [childrenField],
                }],
              },
            ],
          },
          /** Personal site */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [
              personalWwwField,
            ],
          },

          /** CONTACTS */
          /** Telegram and VK */
          {
            type: 'row',
            props: { titleType: 'heading', elementsGrow: true },
            title: translate('hubstr.resident.form.contacts.label'),
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [telegramField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [emailField],
                }],
              },
            ],
          },

          /** Facebook and Instagram */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [facebookField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [instagramField],
                }],
              },
            ],
          },
        ],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      /** Expertise, Goal (target) for a year, achievements, resources (to provide and receive)  */
      {
        type: 'column',
        title: translate('hubstr.resident.form.useful'),
        props: { titleType: 'heading' },
        fields: [
          expertiseField,
          achievementsField,
          provideResourcesField,
        ],
      },
      /** Business information */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_business'),
        props: { isPaper: true, titleType: 'heading' },
        layout: [
          /** Work experience in years */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [businessStartYearField],
          },
          /** Turnover (money flow) per year */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [turnoverPerYearField],
          },
          /** Number of employees */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [numberOfEmployeesField],
          },
        ],
      },
      /** Look information */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_look'),
        props: { isPaper: true, titleType: 'heading' },
        layout: [
          /** Year target */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [userYearTarget],
          },
          /** Look resources */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [userLookResources],
          },
          /** Look expertise */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [userLookExpertise],
          },
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [userRequest],
          },
        ],
      },
    ],
  },
];
